import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import HomeTopSection from '../components/home-top-section';
import HomeCallToAction from '../components/home-call-to-action';
import FundraiserCTA from '../components/fundraiser-cta';
import RichardStory from '../components/richard-story';

const IndexPage = () => (
  <Layout>
    <Helmet title={'Free Richard Delisi - Life in Prison for Cannabis'} />
    <HomeTopSection />
    <RichardStory />
    <HomeCallToAction />
  </Layout>
);

export default IndexPage;
